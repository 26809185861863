import './navbar.scss';
import NavItem from './navItem';
import { useState } from 'react';

// Icons
import right from '../../assets/logo/right.svg';
import stethoscope from "../../assets/icons/sthethoscope.svg"

import cpLogo from '../../assets/logo/cp.svg';
import billingActive from '../../assets/logo/billing-active.svg';

import billing from '../../assets/logo/billing.svg';
import patients from '../../assets/icons/patients-inactive.png';

import defDashboardIcon from '../../assets/logo/dashboard-inactive.svg';
import notification from '../../assets/logo/notification.svg';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Navbar = () => {
  const [toggleProfile, setToggleProfile] = useState(false);
 const {user} = useSelector((state) => state.auth)

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="navbar-root">
      <div className="navbar-container">
        <div onClick={() => navigate('/dashboard')}>
          <img src={cpLogo} alt="cp-logo" />
        </div>

        <div className="navbar-nav-items">
          <NavItem
            to="/dashboard"
            text="Patients"
            icon={patients}
            isActive={true}
            hoveredIcon={patients}
          />
       
           <NavItem
            hoveredIcon={billingActive}
            to="/dashboard/billing"
            text="Billing"
            icon={billing}
          />
       
          <NavItem
            to="/provider"
            text="Providers"
            icon={stethoscope}
          
            hoveredIcon={stethoscope}
          />
        </div>

        <div className="navbar-notification">
          <img
            src={notification}
            alt="notification"
            className="notification-icon"
          />
          <div>
            <div className="navbar-profile-toggle">
              <small>{`${user.firstName}`}</small>
              <img
                src={right}
                alt="right"
                onClick={() => setToggleProfile(!toggleProfile)}
              />
            </div>
            {toggleProfile && (
              <div className="navbar-profile">
                <div className="navbar-arrow-div"></div>
                <div className="navbar-profile-container">
                  <hr className="separator" />
                  <h6 className="profile" onClick={logOut}>
                    Logout
                  </h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
