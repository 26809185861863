import React, { useEffect, useState } from 'react';
import './customTable.scss';
import { useNavigate } from 'react-router-dom';
import defaultProfilePicture from '../../assets/logo/userAvatar.svg';

import { Skeleton } from 'antd';
import CurrentPatientsTable from '../Tables/currentPatients';
import PendingPatientsTable from '../Tables/pendingPatients';
import { fetchPatients } from '../../redux/actions/patientAction';

import { useSelector } from 'react-redux';



const CustomTable = ({searchInput}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [patients, setPatients] = useState([]);
 
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('CURRENT');
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const practiceId = user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

 
 
 

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
  
      try {       
        const kycStatus = activeTab === 'CURRENT' ? 'approved' : 'pending';
        let params = {};
        if (searchInput) {
          params = { q: searchInput };
        }
        const  response = await fetchPatients({
         ...params,
         kycStatus,
         page: currentPage,
         limit: 10,
         practiceId: practiceId
        });
       
          setPatients(response?.patients);
          setLoading(false);
         
        setTotalPages(response?.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
 
   const debounceSearch = setTimeout(() => {
    fetchData();
  }, 1000);

  return () => clearTimeout(debounceSearch);
  }, [currentPage, activeTab, searchInput]);

  const displayPageNumbers = () => {
    let pageNumbers = [];
    const maxPageNumbers = 8;

    for (
      let i = currentPage - 1;
      i > 0 && pageNumbers.length < maxPageNumbers / 2;
      i--
    ) {
      pageNumbers.unshift(i);
    }

    pageNumbers.push(currentPage);

    for (
      let i = currentPage + 1;
      i <= totalPages && pageNumbers.length < maxPageNumbers;
      i++
    ) {
      pageNumbers.push(i);
    }

    while (pageNumbers.length < maxPageNumbers && pageNumbers[0] > 1) {
      pageNumbers.unshift(pageNumbers[0] - 1);
    }

    while (
      pageNumbers.length < maxPageNumbers &&
      pageNumbers[pageNumbers.length - 1] < totalPages
    ) {
      pageNumbers.push(pageNumbers[pageNumbers.length - 1] + 1);
    }

    return pageNumbers;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const displayPageNumber = (
    <div className="paginationContainer">
      <div className="pagination-button-container">
      <span
        className={`page-number ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={handlePrevPage}
      >
         Prev
      </span>

      {displayPageNumbers().map((pageNumber) => (
        <span
          key={pageNumber}
          className={`page-number ${
            pageNumber === currentPage ? 'active' : ''
          }`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </span>
      ))}

      <span
        className={`page-number ${
          currentPage === totalPages ? 'disabled' : ''
        }`}
        onClick={handleNextPage}
      >
        Next 
      </span>
      </div>
      
    </div>
  );

  return (
    <div className="custom-table">
      <div className="custom-table-top-tabs">
        <div className="custom-table-top-tabs-items">
          <span
            onClick={() => setActiveTab('CURRENT')}
            className={activeTab === 'CURRENT' ? 'tabs-active' : ''}
          >
            Current Patients
          </span>
          <span
            onClick={() => setActiveTab('INVITED')}
            className={activeTab === 'INVITED' ? 'tabs-active' : ''}
          >
            Invited Patients
          </span>
        </div>
      </div>

      {loading ? (
        Array.from({ length: 4 }, (_, index) => (
          <div className="skeleton" key={index}>
            <Skeleton key={index} paragraph={false} active />
          </div>
        ))
      ) : (
        <>
          {activeTab === 'CURRENT' ? (
            <CurrentPatientsTable
              defaultProfilePicture={defaultProfilePicture}
              navigate={navigate}
              data={patients || []}
            />
          ) : (
            <PendingPatientsTable
              defaultProfilePicture={defaultProfilePicture}
              navigate={navigate}
              data={patients || []}
            />
          )}
          <div>{displayPageNumber}</div>
        </>
      )}
    </div>
  );
};

export default CustomTable;
