import React from 'react';
import "./providerUsersTable.scss"
import ProviderCustomTable from './providerCustomTable';
import searchIcon from '../assets/icons/search.svg';
import { useState } from 'react';

const ProviderUsersTable = () => {
  const [searchInput, setSearchInput] = useState('');
  return (
    <div className="dashboard-users-table">
      <div className="dashboard-users-table-header">
        <div className="du-patient-list">
          <p>Provider List</p>
          <div className="searchContainer">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              placeholder="Provider Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        <div className="du-view-all"></div>
      </div>
      <div>
        <ProviderCustomTable searchInput={searchInput} />
      </div>
    </div>
  );
};
export default ProviderUsersTable;