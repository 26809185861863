import React, { useEffect, useState } from 'react';

import userAvatar from '../../assets/logo/userAvatar.svg';
import styles from './dashboardUserDetails.module.scss';
import GoBackButton from '../../components/GoBackButton/GoBackButton';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPatientById } from '../../redux/actions/patientAction';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import DetailsCareTeam from './DetailsInformation/detailsCareTeam';
import DocumentUpload from './DocumentUpload/DocumentUpload';


const DashboardUserDetails = () => {

  const dispatch = useDispatch();
  const { id: patientId } = useParams(); 
  const [loading, setLoading] = useState(false)
  const [patientInfo, setPatientInfo] = useState(null)
  




  useEffect(() => {
    const fetchPatientInfo = async () => {
      setLoading(true)
      try {
        const actionResult = await dispatch(fetchPatientById({ id: patientId }));
        const patientData = actionResult.payload;
        setPatientInfo(patientData);
      } catch (error) {
        console.error('Error fetching patient data:', error);
       
      }finally{
        setLoading(false)
      }
    };

    if (patientId) {
      fetchPatientInfo();
    }
  }, [dispatch, patientId]);
 
  return (
    <div className={styles.root}>
      <GoBackButton to={-1} />
      <div className={styles.patientDetails}>
      {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(255, 255, 255, 0.95)',
              zIndex: 1000,
            }}
          >
            <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
          </div>
        )}

        <h1 className={styles.patientHeader}>Patient Details</h1>
        <div className={styles.details}>
          <div className={styles.patientProfile}>
            <img src={userAvatar} alt="" />
            <div className={styles.user}>
              <h1>
                {`${patientInfo?.firstName} 
                ${patientInfo?.lastName}`}
              </h1>
              <span>Patient</span>
            </div>
          </div>
        </div>
      </div>
      {patientInfo && (
        <>
        <DetailsCareTeam    generalInfo={patientInfo}  careTeam={patientInfo}  patientId={patientId}/>
        <DocumentUpload documentinfo={patientInfo}  patientId={patientId}/>
        </>
      )}
    </div>
  );
};

export default DashboardUserDetails;
