import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../../api';
import { Spin } from 'antd';
import styles from "./pendingPatient.module.scss"

const PendingPatientsTable = ({ data, navigate, defaultProfilePicture }) => {
  const [loading, setLoading] = useState(false);

  const handleResend = async (e, patient) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      patientEmail: patient.email,
      practitionerId: patient.practitionerId,
      phone: patient.phone,
      firstName: patient.firstName,
      lastName: patient.lastName,
    };
    console.log('formData', formData);

    try {
      const response = await api.post('/practices/invite-patient', formData);
      toast.success('Invitation sent successfully!');
    } catch (error) {
      console.log('error', error);
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Failed to send invitation';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles["ant-spin-dot-item"]} />
        </div>
      ) : null}

      <table>
        <thead>
          <tr className="table-head">
            <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>PATIENT</th>
            <th style={{ fontFamily: 'Avenir' }}>EMAIL</th>
            <th style={{ fontFamily: 'Avenir' }}>PHONE Number</th>
            <th style={{ fontFamily: 'Avenir' }}>DATE INVITED</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((patient) => (
              <tr className="table-row" key={patient.id}>
                <td>
                  <div className="custom-table-image">
                    <img
                      src={defaultProfilePicture}
                      alt={patient.firstName}
                      className=""
                    />
                    <p>{`${patient.firstName || ''} ${patient.lastName || ''}`}</p>
                  </div>
                </td>
                <td>{patient.email}</td>
                <td>{patient.phone}</td>
                <td>{moment(patient.dateInvited).format('LL')}</td>
                <td>
                  <div>
                    <button
                      onClick={(e) => handleResend(e, patient)}
                      className="table-details-button"
                      disabled={loading}
                    >
                     Resend
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PendingPatientsTable;
