import "./invite.scss";
import logo from "../../components/images/dashboard_logo.svg";
import { useEffect, useState } from "react";
import { api } from "../../api";
import { Form, Input, Select } from "antd";
import { phoneCodes } from "../../helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const InvitePatient = () => {
  const [step, setStep] = useState(1);
  const [provider, setProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("+61");

  const { Option } = Select;

  const selectCode = (
    <Select defaultValue="+61" value={code} onChange={setCode}>
      {phoneCodes
        .sort((a, b) => a.dial_code - b.dial_code)
        .map((item, index) => (
          <Option value={item.dial_code} key={index}>
            {item.dial_code}
          </Option>
        ))}
    </Select>
  );
  const navigate = useNavigate();

  const getPractitioners = async () => {
    const data = await api.get("/gp/all-practitioners?status=approved");
    setProviders(data.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  const handleInvite = async () => {
    try {
      const data = await api.post("/gp/invite-patient", {
        email,
        firstName,
        lastName,
        phoneNumber: `${code}${phoneNumber}`,
        practitionerId: provider,
      });
      toast(data.data.message);
      setStep(1);
      navigate("/overview");
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <img src={logo} className="img" alt="logo" />
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex justify-content-start my-3 mx-3">
          <button
            className="btn btn-primary"
            onClick={() => navigate("/overview")}
          >
            Back to Overview
          </button>
        </div>
        {step === 1 && (
          <div className="card py-5 px-3 text-center border-0 bg-white">
            <h6>Invite a Patient</h6>
            <p className="w-75 mx-auto">
              Add a patient to receive care from a practitioner.
            </p>
            <button
              className="btn btn-secondary text-white mx-auto"
              onClick={() => setStep(2)}
            >
              Invite Patient
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="card py-5 px-3 border-0 bg-white">
            <h6 className="text-center">Choose a care provider</h6>
            <p className="w-75 mx-auto text-center">
              Designate which care provider you wish to assign to this patient.
            </p>
            <div className="text-left mt-3 mx-4">Select care provider</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Provider is required",
                },
              ]}
              className="mx-3"
              name={"provider"}
            >
              <Select
                showSearch
                placeholder="Select a provider"
                onChange={setProvider}
                value={provider}
                loading={loading}
                filterOption={(input, option) =>
                  option.children
                    .map((item) => item?.props?.children)
                    .join(" ")
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                size="large"
              >
                {providers.map((option) => (
                  <Option key={option._id}>
                    <p>{`${option.firstName} ${option.lastName}`}</p>
                    <span className="type">{option.type}</span>
                    <hr />
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <button
              className="btn btn-secondary text-white mx-auto px-5 mt-3"
              onClick={() => setStep(3)}
            >
              Continue
            </button>
            <button
              className="btn text-primary mt-3"
              onClick={() => setStep(1)}
            >
              Go back
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="card py-5 px-3 border-0 bg-white">
            <h6 className="text-center">Add Patient Details</h6>
            <p className="w-75 mx-auto text-center">
              Provide patient's name, phone number and email address to send an
              invite.
            </p>
            <div className="text-left mt-3 mx-4 mb-2">First Name</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
              className="mx-3"
              name={"firstName"}
            >
              <Input
                placeholder="First Name"
                size="large"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>
            <div className="text-left mt-3 mx-4 mb-2">Last Name</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                },
              ]}
              className="mx-3"
              name={"lastName"}
            >
              <Input
                placeholder="Last Name"
                size="large"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
            <div className="text-left mt-2 mx-4 mb-2">Email</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
              className="mx-3"
              name={"email"}
            >
              <Input
                placeholder="@"
                size="large"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <div className="text-left mt-2 mx-4 mb-2">Phone Number</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
              ]}
              className="mx-3"
              name={"phoneNumber"}
            >
              <Input
                addonBefore={selectCode}
                size="large"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Item>

            <button
              type="submit"
              className="btn btn-secondary text-white mx-auto px-5 mt-3"
              onClick={handleInvite}
            >
              Continue
            </button>
            <button
              className="btn text-primary mt-3"
              onClick={() => setStep(2)}
            >
              Go back
            </button>
          </div>
        )}
      </div>
    </>
  );
};
