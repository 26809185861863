import LoginForm from '../components/LoginForm';
import SignInLayout from '../layouts/signInLayouts';
import './LoginPage.scss';

const LoginPage = () => {
  return (
    <SignInLayout>
      <div className="login-page-container">
        <LoginForm />
      </div>
    </SignInLayout>
  );
};

export default LoginPage;
