
import React from 'react';
import moment from "moment"


const PendingProviderTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>PROVIDER</th>
          <th style={{ fontFamily: 'Avenir' }}>AHPRA NUMBER</th>
          <th style={{ fontFamily: 'Avenir' }}>SUBURB</th>
          <th style={{ fontFamily: 'Avenir' }}>DATE OF APPROVAL</th>
          <th style={{ fontFamily: 'Avenir' }}>STATUS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { Array.isArray(data) && data.map(user=> (
          <tr className="table-row" key={user?._id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={user?.firstName}
                  className=""
                />
                <p>{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
            </td>
            <td>55</td>
            <td>{user?.city}</td>
            <td>{moment(user?.createdAt).format('LL')}</td>
       
            <td>
              <div>
                <button
                  onClick={() =>
                    navigate(`/dashboard/user-details/${user._id}`)
                  }
                  className="table-details-button"
                >
                  See Details
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PendingProviderTable;
