import React, { useState } from 'react';
import './FormInput.scss';
import hidePassword from "../assets/icons/hidePassword.svg";

const Input = ({ label, className, name, type, hasError, value, onChange, inputClassName }) => {
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    setIsActive(value !== '');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={`floating-input ${isActive ? 'active' : ''} ${
        hasError ? 'error' : ''
      } ${className}`}
    >
      <input
        name={name}
        type={showPassword ? "text" : type}
        value={value}
        onChange={onChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className={inputClassName}
      />
      <label>{label}</label>
      {type === "password" && (
        <img
          src={hidePassword}
          alt="Toggle password visibility"
          className="password-toggle"
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
  );
};

export default Input;
