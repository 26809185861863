
import { createAsyncThunk } from "@reduxjs/toolkit";
import {api} from "../../api"

export const fetchPatients = async ({

 limit,
  page,
  practiceId,
kycStatus,
q,
}) => {
  try {
    const response = await api.get('/practices/patients', {
      params: {page, limit, practiceId, kycStatus, q },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


export const fetchPatientById = createAsyncThunk(
  'patient/fetchPatientById',
  async ({ id }, thunkAPI) => {
    try {
      const response = await api.get(`/users/${id}`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const invitePatient = async (data) => {
  try {
    const response = await api.post('/practices/invite-patient', data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
