import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './goBackButton.module.scss';
import { useDispatch, useSelector } from 'react-redux';


const GoBackButton = ({ to, onClick }) => {
  const navigate = useNavigate();

  
  const handleGoBack = () => {
    navigate('/dashboard');
  };

  return (
    <button
      onClick={onClick ? onClick : handleGoBack}
      className={styles.backButton}
      alt=""
    >
      {'< '}
      {'Go Back'}
    </button>
  );
};

export default GoBackButton;
