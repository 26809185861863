// src/redux/store.js
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import thunk from 'redux-thunk'; // Import Redux Thunk
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
   
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // Include logger only in development environment
  const { createLogger } = require('redux-logger');
  const logger = createLogger();
  middlewares.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware(),
    ...middlewares,

  ],
});

const persistor = persistStore(store);

export { store, persistor };
