import { Spin, Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";

export const CurrentPatients = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPatients = async () => {
    const data = await api.get("/user/?userType=patient&status=approved");
    setPatients(data.data.data);
    setLoading(false);
   
  };

  useEffect(() => {
    getPatients();
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Payment Card",
      dataIndex: "payment",
      key: "payment",
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-start my-3 mx-3">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/overview")}
        >
          Back
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card px-3 py-5 my-5 mx-auto">
          <h4 className="mb-3">Current Patients</h4>
          {loading ? (
            <Spin tip="Loading">
              <div className="content" />
            </Spin>
          ) : (
            <Table dataSource={patients} columns={columns} />
          )}
        </div>
      </div>
    </div>
  );
};
