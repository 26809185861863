import React from 'react';
import styles from "./customCheckBox.module.scss"

const CustomCheckbox = ({checked, onChange}) => {
  return (
    <label className={styles.customCheckbox}>
      <input type="checkbox"  checked={checked} onChange={onChange} />
      <span class={styles.checkmark}></span>
    </label>
  );
};

export default CustomCheckbox;