import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import addIcon from '../../../assets/logo/add.svg';
import download from '../../../assets/icons/Download.svg';
import styles from "./documentUpload.module.scss";
import Button from '../../../components/Button';
import { notification, Spin } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 import { api } from '../../../api';
import axios from 'axios';

const DocumentUpload = ({ documentinfo, patientId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  console.log("patientId", patientId)

  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
  };

 console.log("document", documentinfo)

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);
     console.log(formData)
      try {
        const response = await api.post('/files/uploads', formData, {
          headers: {
            'x-document-type': "users-document",
            'x-patient-id': patientId,
          },
        });

        if (response.status === 200) {
          toast.success('File uploaded successfully');
          
        } else {
          toast.error(`File upload failed: ${response.data.message}`);
        }
      } catch (error) {
        console.log("error", error)
        toast.error(`Error uploading file: ${error.response?.data?.message || error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDownload = async (documentId) => {
    setLoading(true);
    try {
      const response = await api.get(`/files/${documentId}`);
      if (response.status === 200) {
        const fileDownloadResponse = await axios.get(response.data.data.fileLink, {responseType: 'blob'})
        if(fileDownloadResponse.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([fileDownloadResponse.data])
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentinfo.documents.find(doc => doc.id === documentId).name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success("File downloaded successfully");
        } 
      } else {
        toast.error(`File download failed: ${response.data.message}`);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(`Error downloading file: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.header}>
          <h1>Document</h1>
          <Button
            label="Upload"
            type="button"
           
            onClick={handleUploadClick}
            style={{ width: "40%", marginLeft: "250px" }}
            isLoading={loading}
          />
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        <div className={styles.teamMembers}>
          {documentinfo && documentinfo.documents && documentinfo.documents.map((document, index) => (
            <div className={styles.member} key={index}>
              <div className={styles.memberImage}>
                <img src={download} alt="" />
                <div>
                  <h3>{`${document.name}`}</h3>
                  <span>{document.type}</span>
                </div>
              </div>
              <span role="button" className={styles.viewButton}
                 onClick={() => handleDownload(document.id)}
              >
                Download
              </span>
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Uploading..." className={styles['ant-spin-dot-item']} />
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;
