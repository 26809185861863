import React, { useState, useEffect } from 'react';
import styles from './invitePatient.module.scss';
import successLogo from '../../../assets/logo/ok.svg';
import ModalInput from "../../modal/modalInput/modalInput";
import FormItem from 'antd/es/form/FormItem';
import PhoneInput from 'antd-phone-input';
import { toast } from 'react-toastify';
import Button from '../../Button';
import { api } from '../../../api';
import { useSelector } from 'react-redux';
import CustomSelectDropDown from "../../CustomSelectDropDown/customSelectDropDown"

const InvitePatient = ({ closeModal }) => {
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('chooseProvider');
  const [providers, setProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [phone, setPhone] = useState('');

  const [formData, setFormData] = useState({
    patientEmail: '',
    practitionerId: '',
    phone: '',
    firstName: '',
    lastName: '',
  });

  const { user } = useSelector((state) => state.auth);
  const practiceId = user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await api.get(`/practices/providers?practiceId=${practiceId}`);
        if (Array.isArray(response?.data?.data?.practitioners)) {
          setProviders(response?.data?.data?.practitioners);
        } else {
          throw new Error('Response is not an array');
        }
      } catch (error) {
        toast.error('Failed to fetch providers');
        console.error(error); 
      }
    };

    if (practiceId) {
      fetchProviders();
    }
  }, [practiceId]);

  const handleProviderSelection = (value) => {
    setSelectedProviderId(value);
    setFormData((prev) => ({
      ...prev,
      practitionerId: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
 
  const handlePhoneNumberChange = (value) => {
   
    const formattedPhoneNumber = `+${value?.countryCode}${value?.areaCode}${value?.phoneNumber}`;
    
    setPhone(value); 
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: formattedPhoneNumber,
    }));
  };
  
  
 

  const handleSendInvite = async (e) => {
    e.preventDefault(); 
    setLoading(true);
    try {
      console.log("formData", formData)
      const response = await api.post('/practices/invite-patient', formData);
      setDisplaySuccess(true);
      toast.success('Invitation sent successfully!');
    } catch (error) {
      console.log("error",error)
      
      const errorMessage = error.response && error.response.data ? error.response.data.message : 'Failed to send invitation';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return displaySuccess ? (
    <div className={styles.successRoot}>
      <img src={successLogo} alt="" />
      <h1>Your invitation is on the way!</h1>
      <span>
        {`You have successfully sent an invitation to ${`${formData.firstName} ${formData.lastName}`}.`}
      </span>
      <button
        type="button"
        onClick={() => closeModal(false)}
        className={styles.inviteButton}
      >
        Close
      </button>
    </div>
  ) : step === 'chooseProvider' ? (
    <div className={styles.rootx}>
      <div className={styles.text}>
        <h1>Choose a care Provider</h1>
  
      </div>
      <form onSubmit={(e) => { e.preventDefault(); setStep('invitePatient'); }}>
        <CustomSelectDropDown
          showSearch={false}
          value={selectedProviderId}
          label="Select Provider"
          data={providers.map(provider => ({ 
            label: `${provider.firstName} ${provider.lastName}`, 
            value: provider._id 
          }))}
          onChange={handleProviderSelection}
        />
        <Button
          label="Continue"
          type="submit"
          isLoading={loading}
        />
      </form>
    </div>
  ) : (
    <div className={styles.root}>
      <div className={styles.text}>
        <h1>Invite a Patient</h1>
        <p>Provide patient’s name, phone number and email address to send an invite.</p>
      </div>
      <form className={styles.form} onSubmit={handleSendInvite}>
        <ModalInput
          className={styles.inputWidth}
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          type="text"
        />
        <ModalInput
          className={styles.inputWidth}
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          type="text"
        />
        <ModalInput
          className={styles.inputWidth}
          label="Email"
          name="patientEmail"
          value={formData.patientEmail}
          type="email"
          onChange={handleChange}
        />
        <div className={styles.phoneNumberInput}>
          <label>Phone Number</label>
          <FormItem name="phone">
            <PhoneInput  defaultValue="+61" enableSearch onChange={(value) => handlePhoneNumberChange(value)} />
          </FormItem>
        </div>
        <Button
          label="Send Invite"
          type="submit"
         isLoading={loading}
        />
      </form>
    </div>
  );
};

export default InvitePatient;
