import { api } from "../../api";
import { loginSuccess } from "../slices/authSlice";



export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const { data } = await api.post('/auth/login', {
        email,
        password,
      });
      const user = data.data.user;
      const hasPracticeManagerRole = user.roles.some(role => role.name === 'Practice Manager');
       
    
      if (!hasPracticeManagerRole) {
        throw new Error('Practice Manager Does not Exist');
      }
   
      if (data.data.token) {
        await localStorage.setItem('token', JSON.stringify(data.data.token));
      }
      return dispatch(loginSuccess(data.data));
    } catch (error) {
        
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    }

    throw error;
    }
  };