import { Outlet } from 'react-router-dom';
import Navbar from '../components/nav/Navbar';
import './DashboardLayouts.scss';


const DashboardLayout = () => {
 

  
  

  return (
    <div className="dashboard-layout-container">
      <Navbar />
      <div className="dashboard-layout-children">
        <div className="dashboard-layout-inner-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
