import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './navitem.scss';
import { useState } from 'react';

const NavItem = ({ hoveredIcon, icon, text, to, notAllowed }) => {
  const [isHovered, setIsHovered] = useState(false);

  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getImageSrc = () => {
    if ((match || isHovered) && hoveredIcon) {
      return hoveredIcon;
    } else {
      return icon;
    }
  };

  return (
    <Link
      onClick={(e) => {
        if (notAllowed) return e.preventDefault();
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="nav-link"
      to={to}
    >
      <div className={`nav-item-container ${match ? 'isActive' : ''}`}>
        <img src={getImageSrc()} alt="dashboard" />
        <h6>{text}</h6>
      </div>
    </Link>
  );
};

export default NavItem;
