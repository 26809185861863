import ProviderUsersTable from '../components/providerUsersTable';
import "./provider.scss";

const Provider = () => {
  return (
    <div className="dashboard-container">
   
      <ProviderUsersTable />
    </div>
  );
};

export default Provider;
