import axios from 'axios';
const deployedUrl = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: deployedUrl});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('token');
  if (token) {
    const accessToken = JSON.parse(token);
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return config;
});

const fetcher = async (arg) => {
  const url = arg[0];
  let params;
  arg.length > 1 ? (params = arg[1]) : (params = {});
  try {
    const response = await api.get(url, { params });

    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export { api, fetcher };
