import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import closeButton from '../../assets/icons/cancel.svg';
import styles from './modal.module.scss';

const Modal = ({ isOpen, onClose, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  if (!isModalOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`${styles.modalOverlay} ${isModalOpen ? styles.open : ''}`}
      onClick={handleOutsideClick}
    >
      <div
        className={`${styles.modalContent} ${isModalOpen ? styles.open : ''}`}
      >
        {children}
        <img
          src={closeButton}
          role="button"
          className={styles.closeButton}
          onClick={() => closeModal()}
          alt=""
        />
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;
