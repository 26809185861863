import styles from "./detailsCareTeam.module.scss";
import addIcon from '../../../assets/logo/add.svg';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import teamMember3 from '../../../assets/logo/userAvatar.svg';
import moment from 'moment';
import Button from "../../../components/Button";
import ModalInput from "../../../components/modal/modalInput/modalInput";
import Modal from "../../../components/modal/modal";
import editIcon from "../../../assets/icons/edit-icon.svg"
import { toast } from "react-toastify";
import { api } from "../../../api";
import { useSelector } from "react-redux";
import CustomSelectDropdown from "../../../components/CustomSelectDropDown/customSelectDropDown";
import { Spin } from "antd";

const Info = ({ title, value, isEditable, inputType, onChange }) => {
  const fieldType = inputType ? inputType : "text";

  const handleChange = (e) => {
    if (isEditable) {
      const newValue = e.target.value;
      onChange(newValue);
    }
  };

  return (
    <div className={styles.info}>
      <span>{title}</span>
      {isEditable ? (
        <input
          type={fieldType}
          value={value}
          onChange={handleChange}
         
          className={styles.editableInput}
        />
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};





const DetailsCareTeam = ({ careTeam, generalInfo, patientId }) => {
  
  const [openAddModal, setOpenAddModal] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableInfo, setEditableInfo] = useState({ ...generalInfo });
  const [editedFields, setEditedFields] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [selectedPractitionerId, setSelectedPractitionerId] = useState(null);
const [providers, setProviders] = useState([])
const [formData, setFormData] = useState({})

  const practiceId = user?.practice && user.practice.length > 0 ? user.practice[0].id : null;

  useEffect(() => {
    
    setEditableInfo({ ...generalInfo });
  }, [generalInfo]);
  useEffect(() => {
    if (!isEditing) {
  
      setEditedFields({});
    }
  }, [isEditing]);
  const locationId = user?.data
  useEffect(() => {
    const fetchPractitioners = async () => {
     
      try {
        const response = await api.get("/practitioners/all-practitioners");
        setProviders(response?.data?.data?.practitioners || []);
        console.log("providersxx", providers)
      } catch (error) {
        console.error("Error fetching practitioners:", error);
        toast.error('Failed to load practitioners');
      }
    };

    fetchPractitioners();
  }, [practiceId]);


  const handlePractitionerSelection = (value) => {
    setSelectedPractitionerId(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      practitionerId: value,
    }));
    console.log("Selected Practitioner ID:", value);
  };
  

  const handleInputChange = (fieldName, newValue) => {
    setEditedFields((prevFields) => ({ ...prevFields, [fieldName]: newValue }));
    setEditableInfo((prevInfo) => ({ ...prevInfo, [fieldName]: newValue }));
  };
  


   


  const handleSave = async () => {
   
  
    setLoading(true);
    try {
     
      const response = await api.patch(`/users/update/${patientId}`, editedFields);
      console.log("Updated patient info:", response);
      setEditableInfo((prevInfo) => ({ ...prevInfo, ...response.data }));
      toast.success('Patient information updated successfully');
      setIsEditing(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating patient information:", error);
      toast.error('Failed to update patient information');
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = {
        patientId,
        practiceId,
        practitionerId: selectedPractitionerId,
      };
      const response = await api.post('/careteams', formData); 
      setOpenAddModal(false)
      setSelectedPractitionerId(null);
        console.log("careteams",response)
      setEmail('');
    
      toast.success('Invite sent successfully');
      
      setLoading(false);
    } catch (error) {
      setOpenAddModal(false)
      setSelectedPractitionerId(null);
      console.log("error:", error)
      if (
        error.response &&
        error.response.data &&
        error.response?.data?.message
      ) {
        toast.error(error.response?.data?.message);
      } else {
        toast.error('Failed to send invite');
      }
      setLoading(false);
    }
  };


  
const handleDeletePractitioner = async (practitionerId) => {
  try {
    setLoading(true);
    const response = await api.delete(`/careteams?practitionerId=${practitionerId}&patientId=${patientId}&practiceId=${practiceId}`);
    console.log("Deleted practitioner", response);
    toast.success('Practitioner deleted successfully');
  
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.error("Error deleting", error.response?.data?.message);
    toast.error(error.response?.data?.message);
  }
};

  return (
    <div className={styles.root}>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.95)",
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles["ant-spin-dot-item"]} />
        </div>
      ) : null}

      <div className={styles.left}>
        <div className={styles.headerLeft}>
          <h1>General Information</h1>
          <div className={styles.addBttn}>
          <img src={editIcon} alt="" />
          <div onClick={() => setIsEditing(!isEditing)}  role="button"
            className={styles.addButton}>
            {isEditing ? 'Cancel' : 'Edit'}
          </div>
          {isEditing && <div role="button" className={styles.addButton} onClick={handleSave}>Save</div>}
          </div>
        </div>

        <div>
          <h1 className={styles.subHeader}>Address</h1>
          <div className={styles.information}>
            <Info
              title="street"
              value={editableInfo.street}
              isEditable={isEditing}
              onChange={(value) => handleInputChange('street', value)}
            />
          </div>

          <div className={styles.information}>
            <div className={styles.addressInfo}>
              <Info
                title="Country"
                value={editableInfo.country}
                isEditable={isEditing}
                onChange={(value) => handleInputChange('country', value)}
              />
              <Info
                title="Suburb"
                value={editableInfo.city}
                isEditable={isEditing}
                onChange={(value) => handleInputChange('city', value)}
              />
              <Info
                title="Postal Code"
                value={editableInfo.postalCode}
                isEditable={isEditing}
                onChange={(value) => handleInputChange('postalCode', value)}
              />
            </div>
          </div>
        </div>

        <h1 className={styles.subHeader}>Other Information</h1>
        <div className={styles.information}>
        <Info
  title="Date of Birth"
  value={moment(editableInfo.dateOfBirth).format("YYYY-MM-DD")}
  isEditable={isEditing}
  inputType="date"
  onChange={(newValue) => handleInputChange("dateOfBirth", newValue)}
/>

          <Info
            title="Gender"
            value={editableInfo.sex}
            isEditable={false}
            onChange={(value) => handleInputChange('sex', value)}
          />
          <Info
            title="Medicare Id"
            value={editableInfo.medicareNum}
            isEditable={false}
            onChange={(value) => handleInputChange('medicareNum', value)}
          />
          <Info
            title="IRN"
            value={editableInfo.medicareReferralNumber}
            isEditable={false}
            onChange={(value) => handleInputChange('medicareReferralNum', value)}
          />
          <Info
            title="Most Recent K10 Score"
            value={editableInfo.recentKTenScore}
            isEditable={false}
            onChange={(value) => handleInputChange('recentKTenscores', value)}
          />
          <Info
            title="Phone"
            value={editableInfo.phoneNumber}
            isEditable={false}
            onChange={(value) => handleInputChange('phoneNumber', value)}
          />
          <Info
            title="Email address"
            value={editableInfo.email}
            isEditable={false}
            onChange={(value) => handleInputChange('emailAddress', value)}
          />
        </div>
      </div>

      {/* Care Team */}
      <div className={styles.right}>
        <div className={styles.headerRight}>
          <h1>Care Team</h1>
          <div
            onClick={() => setOpenAddModal(true)}
            role="button"
            className={styles.addButton}
          >
            <img src={addIcon} alt="" />
            <h2>Add to team</h2>
          </div>
        </div>
        <div className={styles.teamMembers}>
          {careTeam && careTeam?.careplanTeam && careTeam?.careplanTeam.map((member, index) => (
           
            <div className={styles.member} key={index}>
              <div className={styles.memberImage}>
                <img src={member.imageUrl || teamMember3} alt="" />
                <div>
                  <h3>{`${member.name}`}</h3>
                  <span>{member.type}</span>
                </div>
              </div>
              <span role="button" className={styles.viewButton}
               onClick={() => handleDeletePractitioner(member.id)}
              >
                Delete
              </span>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
        children={
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h1>Add to Care Team</h1>
              <span>
               Select a practitioner you want to add to the patient careteam.
              </span>
            </div>
            <form>
            <CustomSelectDropdown
              
              value={selectedPractitionerId}
             label="Select Provider"
             data={Array.isArray(providers) ? providers.map(provider => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider._id
            })) : []}
             onChange={handlePractitionerSelection}
            />

              <Button
              onClick={handleInvite}
                label="Send Invite"
                isLoading={loading}
              />
            </form>
          </div>
        }
      />
    </div>
  );
};

export default DetailsCareTeam;
