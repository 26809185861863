import React, { useState } from 'react';

import PlusIcon from '../../assets/icons/plus.svg';
import './DashboardProfile.scss';
import Modal from '../modal/modal';
import InvitePatient from './invitePatient/invitePatient';


const DashboardProfile = ({ isKycApproved = true }) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  return (
    <>
      <div className="dashboard-profile">
       
        <div className="dashboard-profile-action">
          {isKycApproved ? (
            <div className="dashboard-profile-action-invite-allowed">
              <button
                onClick={() => setOpenInviteModal(true)}
                className="dashboard-profile-invite"
              >
                <span>Invite Patient</span>
              </button>
            </div>
          ) : (
            <div className="dashboard-profile-action-invite-disabled">
              <img src={PlusIcon} alt="" />
              <span>Invite Patient</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        children={<InvitePatient closeModal={setOpenInviteModal} />}
      />
    </>
  );
};

export default DashboardProfile;
