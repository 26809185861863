import React, { useState } from 'react';
import styles from './billingModal.module.scss';
import Button from '../../../components/Button';
import { api } from '../../../api';

const BillingModal = ({ transactionDetails }) => {
  const [loading, setLoading] = useState(false)
  if (!transactionDetails || !transactionDetails.data) {
    return null; 
  }

  const {
 
    data: {
      claimId,
      flags,
      items,
      location,
      medicareClaimId,
      medicareError,
      patient,
      provider,
      timeReceived,
      transactionId,
      type,
      status,
    
    },
    message,
  } = transactionDetails;

  const {
    chargeAmount,
    date,
    itemNumber,
    MedicareVoucherId,
    MedicareServiceId,
  } = items[0] || {}; 
  const firstName = patient?.name?.first || {}
  const lastName = patient?.name?.family || {}
  const {dateOfBirth, gender, medicare } = patient || {};
  const { name: locationName, type: locationType } = location || {};

  const handleDownload = async () => {
    //download logic
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Billing</h1>
        <span>Payment for mental health treatment</span>
        <p>{status}</p>
      </div>
      <div className={styles.contentsContainer}>
        <Content label="ClaimId" value={claimId} />
        <Content label="Medicare Claim ID" value={medicareClaimId} />
        <Content label="Transaction ID" value={transactionId} />
        <Content label="Time received" value={timeReceived} />
        <Content label="Type" value={type} />
        <Content label="Charge Amount" value={`$${chargeAmount}`} />
        <Content label="Item Number" value={itemNumber} />
        <Content label="Patient Name" value={`${firstName} ${lastName}`} />
        <Content label="Date of Birth" value={dateOfBirth} />
        <Content label="Gender" value={gender} />
        <Content label="Location Name" value={locationName} />
        <Content label="Location Type" value={locationType} />
      </div>
      <div className={styles.btn}>
        <Button
          label="Download Lodgment"
          onClick={handleDownload}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

const Content = ({ label, value }) => (
  <div className={styles.content}>
    <span>{label}</span>
    <p>{value}</p>
  </div>
);

export default BillingModal;
