import React from 'react';
import moment from 'moment';



const calculateAge = (dateOfBirth) => {
  const today = moment();
  const birthDate = moment(dateOfBirth);
  const years = today.diff(birthDate, 'years');
  return years;
};

const CurrentPatientsTable = ({ data, navigate, defaultProfilePicture }) => {

  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Patient</th>
          <th style={{ fontFamily: 'Avenir' }}>Age</th>
          <th style={{ fontFamily: 'Avenir' }}>Suburb</th>
          <th style={{ fontFamily: 'Avenir' }}>Most Recent Visit</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(data) && data.map((patient) => (
          <tr className="table-row" key={patient?.id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={patient?.firstName}
                  className=""
                />
                <p>{`${patient.firstName || ''} ${patient.lastName || ''}`}</p>
              </div>
            </td>
            <td>{calculateAge(patient.dateOfBirth)}</td>
             <td>{patient.location || ''}</td>
              <td>{moment(patient.mostRecentVisit || '').format('LL')}</td>
            
            <td>
              <div>
                <button
                  onClick={() =>
                    navigate(`/dashboard/user-details/${patient?.id}`)
                  }
                  className="table-details-button"
                >
                  See Details
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
     );
};

export default CurrentPatientsTable;
