import React, { useEffect, useState } from 'react';
import styles from './billingTable.module.scss';
import moment from 'moment';
import { Spin } from 'antd';
import Modal from '../../components/modal/modal';
import { api } from '../../api';
import { useSelector } from 'react-redux';
import BillingModal from './billingModal/billingModal';

const BillingTable = ({
  startDate,
  finishDate,
  practitionerId,
  currentPage,
  setCurrentPage,
  shouldFilter,
}) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const practiceId = user?.practice[0]?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await api.get("/practices/transactions", {
          params: {
            page: currentPage,
            limit: 20,
            practiceId,
            startDate,
            endDate: finishDate,
            practitionerId,
          },
        });

        if (
          response?.data &&
          response?.data?.data &&
          response.data?.data?.history
        ) {
          setDisplayedItems(response?.data?.data?.history);
          setTotalPages(response?.data?.data?.totalPages);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, shouldFilter]);


  const handleClick = async (transactionId) => {
    try {
      setLoadingDetails(true);
      const response = await api.get(`/practices/single-transaction?transactionId=${transactionId}`);
      setSelectedTransaction(response?.data);
    
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const displayPageNumbers = () => {
    const pageNumbers = [currentPage];

    for (let i = 1; i <= 2 && currentPage + i <= totalPages; i++) {
      pageNumbers.push(currentPage + i);
    }

    for (let i = 1; i <= 2 && currentPage - i > 0; i++) {
      pageNumbers.unshift(currentPage - i);
    }

    return pageNumbers;
  };

  return (
    <div>
      {loading || loadingDetails ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.95)",
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles["ant-spin-dot-item"]} />
        </div>
      ) : null}

      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr className={styles.tableHeader}>
              <th style={{ textAlign: "start", fontFamily: "Avenir" }}>
                Provider
              </th>
              <th style={{ fontFamily: "Avenir" }}>Provider ID</th>
              <th style={{ fontFamily: "Avenir" }}>Billing Type</th>
              <th style={{ fontFamily: "Avenir" }}>Item Number</th>
              <th style={{ fontFamily: "Avenir" }}>Patient</th>
              <th style={{ fontFamily: "Avenir" }}>Date</th>
              <th style={{ fontFamily: "Avenir" }}>Amount</th>

              <th style={{ fontFamily: "Avenir" }}>Claim Status</th>
              <th></th>
            </tr>
          </thead>
          
          <tbody>
            {displayedItems.map((row, index) => (
              <tr className={styles.tableRow} key={index}>
                <td>{row.practitioner.name}</td>
                <td>{row.practitioner.providerId}</td>
                <td>{row.transaction.type}</td>
                <td> {row.transaction.itemNumber}</td>
                <td>{row.patient.name}</td>
                <td>
                  {moment(row.transaction.createdAt).format("MMMM Do YYYY")}
                </td>
                <td>${row.charge.amount}</td>

                <td>{row.transaction.status}</td>
                <td>
                  <button
                    onClick={() => handleClick(row.transaction.id)} 
                    className="table-details-button"
                  >
                    See Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="paginationContainer">
        <span
          className={`page-number ${currentPage === 1 ? "disabled" : ""}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &lt; Prev
        </span>

        {displayPageNumbers().map((pageNumber) => (
          <span
            key={pageNumber}
            className={`page-number ${
              pageNumber === currentPage ? "active" : ""
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </span>
        ))}

        <span
          className={`page-number ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next &gt;
        </span>
      </div>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false)
          
        }}
        children={
          <BillingModal
          transactionDetails={selectedTransaction}
        
          />
        }
      />

    </div>
  );
};

export default BillingTable;
